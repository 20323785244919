<template>
  <div class="">
    <div class="row justify-content-between align-items-end">
      <div class="col-auto">
        <label for=""> Désignation de stock </label>
        <select v-model="filter.stock_fullName" class="form-select">
          <option value="">---</option>
          <option
            v-for="(stockFullName, index) in stockFullNames"
            :key="index++"
            :value="stockFullName.fullName"
          >
            {{ stockFullName.fullName }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="">Catégorie</label>
        <select v-model="filter.section_reference" class="form-select">
          <option value="">---</option>
          <option
            v-for="(section, index) in sections"
            :key="index++"
            :value="section.reference"
          >
            {{ section.fullName }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Produit</label>
        <select v-model="filter.item_reference" class="form-select">
          <option value="">---</option>
          <option
            v-for="(item, index) in items.filter(
              (p) => p.section_reference == filter.section_reference
            )"
            :key="index++"
            :value="item.reference"
          >
            {{ item.fullName }}
          </option>
        </select>
      </div>
      <div class="col">
        <label for="">Du</label>
        <input type="date" class="form-control" v-model="filter.startDate" />
      </div>
      <div class="col">
        <label for="">Au</label>
        <input type="date" class="form-control" v-model="filter.endDate" />
      </div>
    </div>
    <br />
    <div class="row justify-content-end align-items-end">
      <div class="col-auto">
        <button @click="toFilter(filter)" class="btn btn-primary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Désignation</th>
          <th scope="col">Date</th>
          <th scope="col">Stock</th>
          <th scope="col" class="text-center">Quantité</th>
          <th scope="col">Status</th>
          <th scope="col">Remarque</th>
          <th scope="col">Creer par</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(history, index) in histories" :key="index++">
          <td>
            {{ index }}
          </td>

          <td>
            <span v-if="history.item">
              {{ history.item.fullName }}
            </span>
          </td>

          <td>{{ history.dateTime | dateTime }}</td>
          <td>
            <span v-if="history.stock">
              <div
                :class="history.stock.deleted_at != null ? 'text-danger' : ''"
              >
                {{ history.stock.fullName }}
              </div>
              <div v-if="!history.stock.fullName">
                {{ history.stock.reference }}
              </div>
            </span>
          </td>
          <td class="text-center">
            <span
              class="text-end"
              :class="history.quantity > 0 ? 'text-success' : 'text-danger'"
            >
              <span v-if="history.quantity > 0"> + </span>
              {{ history.quantity }}
            </span>
          </td>

          <td class="">
            <span class="text-success"> {{ history.status }} </span>
          </td>

          <td class="">
            <span class="text-danger"> {{ history.remark }} </span>
          </td>
          <td>
            <span v-if="history.user">
              {{ history.user.name }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {},
    };
  },
  computed: {
    ...mapGetters("stock", {
      histories: "getStockHistories",
      stockFullNames: "getStockFullNames",
    }),
    ...mapGetters("item", {
      items: "getAll",
    }),
    ...mapGetters("section", {
      sections: "getAll",
    }),
  },
  async mounted() {
    this.$store.dispatch("stock/fetchStockHistories");
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("stock/fetchStockFullNames");
    this.$store.dispatch("section/getAll");
    this.$store.dispatch("item/getAll");
  },
  methods: {
    async refresh() {
      this.$store.dispatch("stock/fetchStockHistories");
      this.$store.dispatch("stock/getAll");
      this.$store.dispatch("stock/fetchStockFullNames");
      this.$store.dispatch("section/getAll");
      this.$store.dispatch("item/getAll");
    },
    async toFilter(filter) {
      await this.$store.dispatch("stock/filterStockHistories", filter);
    },
  },
  filters: {
    dateTime: (value) => {
      return moment(value).format("DD-MM-YYYY hh:mm");
    },
  },
};
</script>
